<template>
  <div>
    <div class="modalPopup image_upload_modal">
      <div class="modal_header">
        <h3 class="title">
          {{ index != -1 ? '이미지 업로드' : '이미지 조회' }}
        </h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <p v-if="index != -1">
          <label for="files" class="btn_sub1">이미지 선택</label>
          <input
            v-show="false"
            id="files"
            :key="reRend"
            @change="uploadImage()"
            type="file"
            name="photo"
            accept="image/*"
          />
        </p>
        <img
          :src="imageSrc"
          class="image"
          v-show="imageSrc != null && !imageSrc.includes('null')"
        />
      </div>
      <div class="modal_footer">
        <div>
          {{ selectedFileName ? selectedFileName : ''
          }}{{ sizeTemp ? ` (${sizeTemp})` : '' }}
        </div>
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button
          type="button"
          class="btn_sub2"
          @click="deleteImage"
          v-if="index != -1"
        >
          삭제
        </button>
        <button type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import MODAL_MIXIN from '@/mixins/modal';
export default {
  mixins: [DRAG_MODAL_MIXIN, MODAL_MIXIN],
  props: {
    uploadedImage: {
      type: String,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showImage: false,
      imageSrc: null,
      error_text: '',
      reRend: 0,
      sizeTemp: null,
      selectedFileName: null,
    };
  },
  methods: {
    deleteImage() {
      this.imageSrc = null;
      this.sizeTemp = null;
      this.selectedFileName = null;
      this.reRend += 1;
    },
    submit() {
      if (this.imageSrc != undefined && this.imageSrc != null) {
        const image = this.lodash.clonedeep(this.imageSrc);
        const indexOf = image.indexOf('base64,');
        const imageBase64 = image.substr(indexOf + 7);
        this.$emit('onselect', {
          image: imageBase64,
          index: this.index,
        });
      } else {
        this.$emit('onselect', {
          image: null,
          index: this.index,
        });
      }
    },
    uploadImage() {
      // this.reRend += 1;
      var file = document.querySelector('input[type=file]').files[0];
      var reader = new FileReader();
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

      reader.onload = e => {
        console.log(e);
        this.imageSrc = e.target.result;
        this.showImage = true;
      };
      reader.onerror = error => {
        alert(error);
        this.showImage = false;
        this.selectedFileName = null;
        this.sizeTemp = null;
        // this.reRend += 1;
      };
      if (file != undefined && validImageTypes.includes(fileType)) {
        if (file.size > 3145728) {
          this.imageSrc = null;
          this.sizeTemp = null;
          this.selectedFileName = null;
          this.openOneButtonModal(
            '업로드 실패',
            '3MB 크기 이하의 이미지 파일만 업로드 가능합니다.',
          );
        } else {
          reader.readAsDataURL(file);
          this.sizeTemp = this.formatBytes(file.size);
          this.selectedFileName = file.name;
        }
      } else {
        this.imageSrc = null;
        this.sizeTemp = null;
        this.selectedFileName = null;
        this.openOneButtonModal(
          '업로드 실패',
          '.png, .jpg, .gif 확장자인 <br />이미지 파일만 업로드 가능합니다.',
        );

        // this.rerend += 1;
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  created() {
    if (this.uploadImage != undefined) {
      this.imageSrc = 'data:image/png;base64,' + this.uploadedImage;
      this.showImage = true;
    }
  },
};
</script>

<style></style>
